import React from "react";
import HopFacts from "../scenes/hopFacts";
import SEO from "../components/seo";
import { keywords } from "../content/keywords";
export default () => {
    return (<>
      <SEO title={'Auxiliary Growers Hop'} keywords={keywords}/>
      <HopFacts />
    </>);
};
