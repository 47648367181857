import { useStaticQuery, graphql } from "gatsby";
export default () => {
    const data = useStaticQuery(graphql `
    query {
      firstImage: file(
        relativePath: { eq: "images/hop_facts/AmazingHopFacts.jpg" }
      ) {
        publicURL
      }
      secondImage: file(relativePath: { eq: "images/hop_facts/Aux_Growers_Trimmed.jpg" }) {
        publicURL
      }
    }
  `);
    return data;
};
// childImageSharp {
//   fluid(maxWidth: 1500, quality: 100) {
//     ...GatsbyImageSharpFluid_withWebp
//   }
// }
