import React from "react";
import Hero from "../../components/hero";
import SectionSeparator from "../../components/sectionSeparator";
import useGetImage from "./useGetImage";
import Content from "./components/content";
import s from "./HopFacts.module.scss";
const HopFacts = () => {
    const { hero } = useGetImage();
    return (<>
      <Hero imgURL={hero.childImageSharp.fluid}/>
      <SectionSeparator />
      <div className={s.wrap}>
        <Content />
      </div>
    </>);
};
export default HopFacts;
