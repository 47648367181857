import React, { useState } from "react";
import useGetImage from "./useGetImage";
import s from "./Content.module.scss";
import "@coreui/coreui/dist/css/coreui.min.css";
// import 'bootstrap/dist/css/bootstrap.min.css'
import { CButton, CCollapse, CCard, CCardBody } from "@coreui/react";
const Content = () => {
    const { firstImage, secondImage } = useGetImage();
    const [visible, setVisible] = useState(false);
    const [visible2, setVisible2] = useState(false);
    const [visible3, setVisible3] = useState(false);
    const [visible4, setVisible4] = useState(false);
    return (<section>
      <div className={s.item}>
        <img className={s.img} src={firstImage.publicURL}/>
        <div className={s.textBox}>
          <h2 className={s.title2}>
            Below are links to the social media pages of a number Amarillo®
            auxiliary growers:
          </h2>
          <div style={{
        width: "80%",
        height: "100%",
        display: "flex",
        margin: "auto",
    }}>
            <div className={s.textBu}>
              <CButton onClick={() => setVisible(!visible)}>
                <h5>Washington</h5>
              </CButton>
              <CButton onClick={() => setVisible2(!visible2)}>
                <h5>Oregon</h5>
              </CButton>
              <CButton onClick={() => setVisible3(!visible3)}>
                <h5>Idaho</h5>
              </CButton>
              <CButton onClick={() => setVisible4(!visible4)}>
                <h5>Germany</h5>
              </CButton>
            </div>
          </div>
        </div>
        <img className={s.img2} src={secondImage.publicURL}/>
      </div>
      <div className={s.item}>
        
        <div className={s.textBox2}>
          <div className={s.text2}>
            <CCollapse visible={visible}>
              <CCard className="mt-3">
                <CCardBody>
                  <h5>Washington</h5>
                  Loza Farms -{" "}
                  <a href={"https://www.instagram.com/lozafarms/"} target={"_blank"}>
                    @lozafarms
                  </a>{" "}
                  <br />
                  Double R Hop Ranches -{" "}
                  <a href={"https://www.instagram.com/doublerhop/"} target={"_blank"}>
                    {" "}
                    @doublerhop{" "}
                  </a>{" "}
                  <br />
                  Roy Farms -{" "}
                  <a href={"https://www.instagram.com/royfarms_hops/"} target={"_blank"}>
                    @royfarms_hops{" "}
                  </a>
                  <br />
                  Puterbaugh Hop Farm -{" "}
                  <a href={"https://www.instagram.com/hopsdirect/"} target={"_blank"}>
                    @hopsdirect
                  </a>
                </CCardBody>
              </CCard>
            </CCollapse>
          </div>
          <div className={s.text2}>
            <CCollapse visible={visible2}>
              <CCard className="mt-3">
                <CCardBody>
                  <h5>Oregon</h5>
                  Crosby Hop Farm <br />{" "}
                  <a href={"https://instagram.com/crosbyhops/"} target={"_blank"}>
                    @crosbyhops{" "}
                  </a>{" "}
                  <br />
                  Coleman Agriculture <br />{" "}
                  <a href={"https://instagram.com/colemanagriculture"} target={"_blank"}>
                    @colemanagriculture
                  </a>
                </CCardBody>
              </CCard>
            </CCollapse>
          </div>
          <div className={s.text2}>
            <CCollapse visible={visible3}>
              <CCard className="mt-3">
                <CCardBody>
                  <h5>Idaho</h5>
                  Gooding Farms{" "}
                  <a href={"https://instagram.com/goodingfarms"} target={"_blank"}>
                    @goodingfarms{" "}
                  </a>{" "}
                  <br />
                  Obendorf Hop, Inc{" "}
                  <a href={"https://www.instagram.com/obendorfhop/"} target={"_blank"}>
                    {" "}
                    @obendorfhop{" "}
                  </a>
                </CCardBody>
              </CCard>
            </CCollapse>
          </div>
          <div className={s.text2}>
            <CCollapse visible={visible4}>
              <CCard className="mt-3">
                <CCardBody>
                  <h5>Germany</h5>
                  Audi Schapfl -{" "}
                  <a href={"https://www.instagram.com/adischapfl/?igsh=MWw4eDB3OHJ4bmgwNg%3D%3D"} target={"_blank"}>
                    @audischapfl
                  </a>{" "}
                  <br />
                  Marion Pichlmeyer-{" "}
                  <a href={"https://www.instagram.com/maroni_pi/?igsh=MWw2N2d5YjN1d3Riaw%3D%3D"} target={"_blank"}>
                    @maroni_pi
                  </a>{" "}
                  <br />
                  Johannes Hagl -{" "}
                  <a href={"https://www.instagram.com/hagljohannes/?igsh=ZWptcXJiOHc4MHBq"} target={"_blank"}>
                    @hagljohannes
                  </a>{" "}
                  <br />
                  Elizabeth Randlkofer -{" "}
                  <a href={"https://www.instagram.com/71bella03/?igsh=MTd5dHc0a2FqNm0yeA%3D%3D"} target={"_blank"}>
                    @71bella03
                  </a>{" "}
                  <br />
                  Bartholomäus Obster -{" "}
                  <a href={"https://www.instagram.com/barthobster/?igsh=YXZtNjJ6eWV6a3lo"} target={"_blank"}>
                    @barthobster
                  </a>{" "}
                  <br />
                  HVG -{" "}
                  <a href={"https://www.instagram.com/hvggermanhops/"} target={"_blank"}>
                    @hvggermanhops
                  </a>{" "}
                  <br />
                </CCardBody>
              </CCard>
            </CCollapse>
          </div>
        </div>
      </div>
    </section>);
};
export default Content;
